.App {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .voter-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .sort-controls {
    margin-bottom: 20px;
  }
  
  .sort-controls button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .voter-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
  }
  
  .voter-card h2 {
    margin-top: 0;
  }