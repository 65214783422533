.table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .voter-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .voter-table th,
  .voter-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .voter-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .voter-table .sortable {
    cursor: pointer;
    position: relative;
  }
  
  .voter-table .sort-icon {
    margin-left: 5px;
    font-size: 12px;
  }
  
  .voter-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .voter-table tr:hover {
    background-color: #f5f5f5;
  }
  
  @media screen and (max-width: 600px) {
    .voter-table {
      font-size: 12px;
    }
    
    .voter-table th,
    .voter-table td {
      padding: 6px;
    }
    
    .hide-mobile {
      display: none;
    }
  }